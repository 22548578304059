import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Patient } from '../models/patient'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private networkService: NetworkService
  ) {}

  async createPatient(
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    state: string,
    memberId: string,
    payerId: string,
    sandbox: string,
    npi: string|null,
    taxonomyCode: string|null,
    placeOfServiceCode: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`patients`, {
        firstName,
        lastName,
        dateOfBirth,
        state,
        memberId,
        payerId,
        sandbox,
        npi,
        taxonomyCode,
        placeOfServiceCode
      }).pipe(
        map((response: any) => {
          return response.patientId
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
