import { toDataURL } from "qrcode"
import { environment } from '../../../environments/environment'

export const generateQRCode = async (
	email: string,
	code: string
): Promise<string> => {
	let issuer = "Sohar%20Health"

	if (environment.name != "prod") {
		issuer += "%20Dev"
	}

	const url = `otpauth://totp/${issuer}:${email}?secret=${code}&issuer=${issuer}`

	return await toDataURL(url)
}
