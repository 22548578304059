<div class="calculator">
  <h1>Patient Responsibility Calculator</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <label>Billed Amount</label>
    <input
      type="number"
      formControlName="billedAmount"
      placeholder="1000"
      required
      />
    <label>Copay</label>
    <input
      type="number"
      formControlName="copay"
      placeholder="20"
      required
      />
    <label>Remaining Deductible</label>
    <input
      type="number"
      formControlName="remainingDeductible"
      placeholder="500"
      required
      />
    <label>Coinsurance</label>
    <input
      type="number"
      formControlName="coinsurance"
      placeholder="0.5"
      required
      />
    <label>Out of Pocket Remaining</label>
    <input
      type="number"
      formControlName="outOfPocketRemaining"
      placeholder="5000"
      required
      />
    <button>Calculate</button>
  </form>
  <div class="result" *ngIf="total != null">
  	<h2>Patient Responsibility: {{ total | currency }}</h2>
  	<ul>
  		<li *ngFor="let line of explanation">
  			{{ line }}
  		</li>
  	</ul>
  	<ul class="notes" *ngIf="notes.length">
  		<li *ngFor="let note of notes">
  			{{ note }}
  		</li>
  	</ul>
  </div>
</div>
