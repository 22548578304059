import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { formatCurrency } from '@angular/common'

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html'
})
export class CalculatorComponent {

  form: UntypedFormGroup
  total: number|null = null
  explanation: string[] = []
  notes: string[] = []

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      billedAmount: [null, [Validators.required]],
      remainingDeductible: [null, [Validators.required]],
      outOfPocketRemaining: [null, [Validators.required]],
      copay: [null, [Validators.required]],
      coinsurance: [null, [Validators.required]],
    })
  }

  onSubmit() {
    const billedAmount = this.form.value["billedAmount"]
    const remainingDeductible = this.form.value["remainingDeductible"]
    let outOfPocketRemaining = this.form.value["outOfPocketRemaining"]
    const copay = this.form.value["copay"]
    const coinsurance = this.form.value["coinsurance"]

    if (
      billedAmount == null ||
      isNaN(billedAmount)
    ) {
      alert("Please enter a numeric value for Billed Amount")
      return
    }

    if (
      remainingDeductible == null ||
      isNaN(remainingDeductible)
    ) {
      alert("Please enter a numeric value for Remaining Deductible")
      return
    }

    if (
      outOfPocketRemaining == null ||
      isNaN(outOfPocketRemaining)
    ) {
      alert("Please enter a numeric value for Out of Pocket Remaining")
      return
    }

    if (
      copay == null ||
      isNaN(copay)
    ) {
      alert("Please enter a numeric value for Copay")
      return
    }

    this.total = 0
    this.explanation = []
    this.notes = []

    let remainingBilledAmount = billedAmount

    if (
      coinsurance == null ||
      isNaN(coinsurance) ||
      coinsurance > 1
    ) {
      alert("Please enter a decimal value for Coinsurance e.g. 0.4 for 40%")
      return
    }

    if (outOfPocketRemaining == 0) {
      this.total = 0
      this.explanation.push("The patient has met their Out of Pocket Maximum for the year")
    }

    if (copay > 0) {
      const amount = Math.min(copay, outOfPocketRemaining)
      this.total += amount
      outOfPocketRemaining -= amount
      remainingBilledAmount -= amount
      const value = formatCurrency(amount, "en-US", "$", "USD")
      const suffix = "*".repeat(this.notes.length + 1)
      this.explanation.push(`${value} copay applied${suffix}`)
      this.notes.push(`${suffix}Copay is typically applied before deductible`)
    } else {
      this.explanation.push(`No copay applied`)
    }

    if (remainingDeductible > 0) {
      const amount = Math.min(Math.min(remainingDeductible, outOfPocketRemaining), remainingBilledAmount)
      this.total += amount
      outOfPocketRemaining -= amount
      remainingBilledAmount -= amount
      const value = formatCurrency(amount, "en-US", "$", "USD")
      this.explanation.push(`${value} taken from deductible`)
    } else {
      this.explanation.push(`Deductible met`)
    }

    if (coinsurance > 0) {
      const amount = Math.min(coinsurance * remainingBilledAmount, outOfPocketRemaining)
      this.total += amount
      remainingBilledAmount -= amount
      const value = formatCurrency(amount, "en-US", "$", "USD")
      const suffix = "*".repeat(this.notes.length + 1)
      this.explanation.push(`${value} coinsurance applied${suffix}`)
      this.notes.push(`${suffix}Coinsurance is typically applied after deductible`)
    } else {
      this.explanation.push(`No coinsurance applied`)
    }

    const value = formatCurrency(remainingBilledAmount, "en-US", "$", "USD")
    this.explanation.push(`Remaining ${value} paid by the payer`)
  }
}
