<div class="header">
	<h1>Reports</h1>
</div>
<table>
	<thead>
		<td>ID</td>
		<td>Created</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let report of reports">
			<td>{{ report.reportId }}</td>
			<td>{{ report.created | date }}</td>
			<td>
				<a class="button" (click)="openReport(report)">Open</a>
			</td>
		</tr>
	</tbody>
</table>
