import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { Provider } from '../models/provider';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getProviders(): Promise<Provider[]> {
    return new Promise((resolve, reject) => {

      this.networkService.get(`organizations`).pipe(
        map((response: any) => {
          return response.organizations.map(organization => {
            return Provider.fromJson(organization)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
