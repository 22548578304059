export class NetworkCoverage {
	constructor(
		public copay: number,
		public coinsurance: number,
		public totalDeductible: number,
		public remainingDeductible: number,
		public outOfPocket: number,
		public outOfPocketRemaining: number,
		public networkStatus: string,
		public tier: number) {}

	static fromJson(data: any): NetworkCoverage {
		return new NetworkCoverage(
			data.copay,
			data.coinsurance,
			data.totalDeductible,
			data.remainingDeductible,
			data.outOfPocket,
			data.outOfPocketRemaining,
			data.networkStatus,
			data.tier)
	}
}
