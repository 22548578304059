<div class="header">
	<h1>Payers</h1>
	<div>
		<a class="download" (click)="didClickDownload()"><img src="assets/download.svg"> Download CSV</a>
	</div>
</div>
<div class="filters">
	<div class="search">
		<input [(ngModel)]="searchValue" type="search" placeholder="Search by payer name or ID" />
	</div>
</div>
<table>
	<thead>
		<td>Name</td>
		<td>Payer ID</td>
		<td>Alias Payer IDs</td>
	</thead>
	<tbody>
		<tr *ngFor="let payer of filteredPayers">
			<td>{{ payer.payerName }}</td>
			<td>{{ payer.payerId }}</td>
			<td>{{ payer.aliasPayerIds.join(", ") }}</td>
		</tr>
	</tbody>
</table>
