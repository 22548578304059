<div class="header">
	<h1>Verification</h1>
	<div>
		<a class="button" (click)="didClickRecreateVerification()">Recreate Verification</a>
	</div>
</div>
<table>
	<thead>
		<td>Name</td>
		<td>Date Of Birth</td>
		<td>State</td>
		<td>Member ID</td>
		<td>Payer</td>
		<td>Status</td>
		<td>Created</td>
		<td></td>
	</thead>
	<tbody *ngIf="verification">
		<tr>
			<td>{{ verification.patient.firstName }} {{ verification.patient.lastName }}</td>
			<td>{{ verification.patient.dateOfBirth }}</td>
			<td>{{ verification.patient.state }}</td>
			<td>{{ verification.memberId }}</td>
			<td>{{ verification.payerName }}</td>
			<td>{{ verification.status }}</td>
			<td>{{ verification.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
			<td>
				<span *ngIf="verification.sandbox" class="sandbox">Sandbox</span>
			</td>
		</tr>
	</tbody>
</table>
<table>
	<thead>
		<td>Plan</td>
		<td>Coverage Period</td>
		<td>Related Entity</td>
		<td>Insurance Type Code</td>
	</thead>
	<tbody *ngIf="verification">
		<tr>
			<td>{{ verification.planName }}</td>
			<td>{{ verification.coveragePeriod }}</td>
			<td>{{ verification.relatedEntities.length ? verification.relatedEntities[0].payerName : null }}</td>
			<td *ngIf="verification.insuranceTypeCode && verification.status?.includes('complete')">{{ verification.insuranceTypeCode }}</td>
		</tr>
	</tbody>
</table>

<table>
	<thead>
		<td>Network Status</td>
		<td>Tier</td>
		<td>Copay</td>
		<td>Coinsurance</td>
		<td>Total Deductible</td>
		<td>Remaining Deductible</td>
		<td>Out of Pocket</td>
		<td>Out of Pocket Remaining</td>
	</thead>
	<tbody *ngIf="verification">
		<tr *ngFor="let benefit of verification.benefits">
			<td>{{ benefit.networkStatus == "inn" ? "In Network" : "Out Of Network" }}</td>
			<td>{{ benefit.tier }}</td>
			<td>{{ benefit.copay | currency }}</td>
			<td>{{ benefit.coinsurance | percent }}</td>
			<td>{{ benefit.totalDeductible | currency }}</td>
			<td>{{ benefit.remainingDeductible | currency }}</td>
			<td>{{ benefit.outOfPocket | currency }}</td>
			<td>{{ benefit.outOfPocketRemaining | currency }}</td>
		</tr>
	</tbody>
</table>