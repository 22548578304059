import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, from, map, Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return from(this.authService.getSession()).pipe(
      catchError(async () => {
        await this.authService.logout();
        return this.router.navigate(['login']);
      }),
      map(() => true)
    );
  }
}
