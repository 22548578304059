import { Component } from '@angular/core'
import { VerificationService } from "../@shared/services/verification.service"
import { HostListener } from '@angular/core'

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html'
})
export class VerificationsComponent {

  sandbox: string = "false"
  verifications: any[] = []
  searchValue: string = ""
  loading = false
  allLoaded = false

  constructor(
    private verificationService: VerificationService
  ) {}

  async ngOnInit() {
    await this.getVerifications()
  }

  async getVerifications() {
    if (
      this.loading ||
      this.allLoaded
    ) {
      return
    }
    this.loading = true
    const sandbox = this.sandbox == "true"
    const searchValue = this.searchValue
    const verifications = await this.verificationService.getVerifications(
      sandbox,
      searchValue,
      this.verifications.length
    )
    if (!verifications.length) {
      this.allLoaded = true
    }
    this.verifications = this.verifications.concat(verifications)
    this.loading = false
  }

  didSelectFilter() {
    this.verifications = []
    this.allLoaded = false
    this.getVerifications()
  }

  timeout = null

  didChangeSearchValue() {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(() => {
      this.verifications = []
      this.allLoaded = false
      this.getVerifications()
    }, 500)
  }

  @HostListener('window:scroll', ['$event.target'])

  windowScrollEvent(event: KeyboardEvent) {
    const heightOfWholePage = window.document.documentElement.scrollHeight
    const heightOfElement = document.documentElement.scrollHeight
    const currentScrolledY = window.scrollY;
    const innerHeight = window.innerHeight
    const spaceOfElementAndPage = heightOfWholePage - heightOfElement

    const scrollToBottom =
      heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage

    if (scrollToBottom < 100) {
      this.getVerifications()
    }
  }
}
