import { NetworkCoverage } from "./network-coverage"
import { CarveOut } from "./carve-out"
import { Subscriber } from "./subscriber"

export class Verification {

	constructor(
		public verificationId: string,
		public memberId: string,
		public payerId: string,
		public sandbox: boolean,
		public status: string,
		public coverageStartDate: string,
		public coverageEndDate: string,
		public planName: string,
		public benefits: NetworkCoverage[],
		public timestamp: string,
		public payerName: string,
		public patientId: string,
		public insuranceTypeCode: string|null,
		public relatedEntities: CarveOut[],
		public subscriber: Subscriber|null,
		public patient: any) {}

	get coveragePeriod(): string|null {
		if (this.coverageStartDate && this.coverageEndDate) {
			return `${this.coverageStartDate} to ${this.coverageEndDate}`
		} else if (this.coverageStartDate) {
			return `From ${this.coverageStartDate}`
		} else {
			return null
		}
	}

	get truncatedVerificationId(): string {
		return this.verificationId.slice(0, 8)
	}

	static fromJson(data: any): Verification {
		return new Verification(
			data.verificationId,
			data.memberId,
			data.payerId,
			data.sandbox,
			data.status,
			data.coverageStartDate,
			data.coverageEndDate,
			data.planName,
			(data.benefits || []).map((benefit) => NetworkCoverage.fromJson(benefit)),
			data.timestamp,
			data.payerName,
			data.patientId,
			data.insuranceTypeCode,
			(data.relatedEntities || []).map((relatedEntity) => CarveOut.fromJson(relatedEntity)),
			data.subscriber ? Subscriber.fromJson(data.subscriber) : null,
			data.patient)
	}
}
