export class Provider {

	constructor(
		public npi: string,
		public organizationName: string
	) { }

	static fromJson(data: any): Provider {
		return new Provider(
			data.npi,
			data.organizationName
		)
	}
}
