<div class="header">
	<h1>Create Verifications</h1>
	<div *ngIf="rows.length">
		<a class="download" (click)="didClickRemoveFile()">Remove File</a>
	</div>
</div>
<table class="rows">
	<thead>
		<td>First Name</td>
		<td>Last Name</td>
		<td>Date Of Birth</td>
		<td>State</td>
		<td>Member ID</td>
		<td>Payer</td>
		<td>NPI Number</td>
		<td>Status</td>
	</thead>
	<tbody *ngIf="rows.length">
		<tr *ngFor="let row of rows">
			<td>{{ row.firstName }}</td>
			<td>{{ row.lastName }}</td>
			<td>{{ row.dateOfBirth }}</td>
			<td>{{ row.state }}</td>
			<td>{{ row.memberId }}</td>
			<td>{{ this.payerMap[row.payerId] }}</td>
			<td>{{ row.npi }}</td>
			<td *ngIf="!row.status"></td>
			<td *ngIf="row.status == 400 || row.status == 500" class="error"><span>Error: {{ row.error }}</span></td>
			<td *ngIf="row.status == 201" class="success"><span>Success</span></td>
		</tr>
	</tbody>
	<tbody *ngIf="!rows.length">
		<tr>
			<td colspan="7">
				<label for="file-upload">Select a file to upload<input id="file-upload" type="file" (change)="onFileSelected($event)" accept=".csv"></label>
			</td>
		</tr>
	</tbody>
</table>
<div class="footer" *ngIf="rows.length">
	<div>
		<span>{{ fileName }}</span>
		<select [(ngModel)]="sandbox" required>
			<option selected value="0">Production</option>
			<option value="1">Sandbox</option>
		</select>
		<a class="button" [class.loading]="loading" (click)="didClickUpload()">Submit</a>
	</div>
</div>
<div class="modal" *ngIf="showModal">
	<div>
		<h2>Review Columns</h2>
		<table>
			<tbody>
				<tr>
					<td>First Name</td>
					<td>
						<select [(ngModel)]="map['firstName']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Last Name</td>
					<td>
						<select [(ngModel)]="map['lastName']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Date Of Birth</td>
					<td>
						<select [(ngModel)]="map['dateOfBirth']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Payer ID</td>
					<td>
						<select [(ngModel)]="map['payerId']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Member ID</td>
					<td>
						<select [(ngModel)]="map['memberId']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>State</td>
					<td>
						<select [(ngModel)]="map['state']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>NPI Number</td>
					<td>
						<select [(ngModel)]="map['npi']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
			</tbody>
		</table>
		<a class="button" (click)="mapFile()">Confirm</a>
	</div>
</div>
