import { Component } from '@angular/core';
import { Verification } from "../@shared/models/verification"
import { VerificationService } from "../@shared/services/verification.service"
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html'
})
export class VerificationComponent {

  verification: Verification

  constructor(
    private verificationService: VerificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    const verificationId = this.route.snapshot.paramMap.get('id')
    this.verification = await this.verificationService.getVerification(verificationId)
  }

  didClickRecreateVerification() {
    if (!this.verification) {
      return
    }

    this.router.navigate(['create-patient'], {
      queryParams: {
        firstName: this.verification.patient?.firstName,
        lastName: this.verification.patient?.lastName,
        dateOfBirth: this.verification.patient?.dateOfBirth,
        state: this.verification.patient?.state,
        memberId: this.verification.memberId,
        payerId: this.verification.payerId
      }
    })
  }
}
