import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkResponse } from '../types/network-response';
import { environment } from '../../../environments/environment';

import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

interface RequestOptions {
  body?: object;
  headers?: HttpHeaders;
  observe?: string;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: string;
  withCredentials?: boolean;
  userDefinedOptionsOnly?: boolean;
  allowCache?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public responseError$ = new Subject<HttpErrorResponse>();
  private accessToken: string|null

  constructor(
    private _http: HttpClient,
    private _authService: AuthService
  ) {}

  public get<T extends NetworkResponse<any>>(
    path: string,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
          RequestType.GET,
          userDefinedOptions,
          this._addBaseUrl(path)
        );
  }

  public post<T>(
    path: string,
    body: object,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
      RequestType.POST,
      userDefinedOptions,
      this._addBaseUrl(path),
      body
    );
  }
  public put<T>(
    path: string,
    body: object,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
      RequestType.PUT,
      userDefinedOptions,
      this._addBaseUrl(path),
      body
    );
  }
  public putExternal<T>(
    uri: string,
    body: object,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
      RequestType.PUT,
      userDefinedOptions,
      uri,
      body
    );
  }
  public patch<T>(
    path: string,
    body: object,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
      RequestType.PATCH,
      userDefinedOptions,
      this._addBaseUrl(path),
      body
    );
  }
  public delete<T>(
    path: string,
    userDefinedOptions?: RequestOptions
  ): Observable<T> {
    return this._doRequest<T>(
      RequestType.DELETE,
      userDefinedOptions,
      this._addBaseUrl(path)
    );
  }

  private _doRequest<T>(
    requestType: RequestType,
    userDefinedOptions?: RequestOptions,
    ...requestArgs: (string | object)[]
  ): Observable<T> {
    return from(this._refreshSession()).pipe(
      mergeMap(() =>
        this._buildRequest<T>(
          requestType,
          userDefinedOptions,
          ...requestArgs
        ).pipe(
          catchError(error => this._responseErrorHandler(error)),
          map(response =>
            this._responseSuccessHandler<T>(response as HttpResponse<T>)
          )
        )
      )
    );
  }

  private _buildRequest<T>(
    requestType: string,
    userDefinedOptions?: RequestOptions,
    ...requestArgs: (string | object)[]
  ): Observable<HttpResponse<T>> {
    const options: RequestOptions =
      this._mergeRequestOptions(userDefinedOptions);
    return this._http[requestType](...requestArgs, options);
  }

  private _mergeRequestOptions(
    userDefinedOptions: RequestOptions
  ): RequestOptions {
    const userOptions: RequestOptions = userDefinedOptions
      ? userDefinedOptions
      : {};
    const userParams: HttpParams = userOptions.params
      ? userOptions.params
      : new HttpParams();
    const params = userOptions.userDefinedOptionsOnly
      ? userParams
      : this._mergeOptionKeys(new HttpParams(), userParams);
    const userHeaders: HttpHeaders = userOptions.headers
      ? userOptions.headers
      : new HttpHeaders();
    const headers = userOptions.userDefinedOptionsOnly
      ? userHeaders
      : this._mergeOptionKeys(this._requestHeaders, userHeaders);
    return Object.assign({}, this._requestOptions, userDefinedOptions, {
      params,
      headers,
    });
  }

  private _mergeOptionKeys(
    httpOption: HttpParams | HttpHeaders,
    userOption: HttpParams | HttpHeaders
  ): HttpParams | HttpHeaders {
    let optionMerge: HttpParams | HttpHeaders = httpOption;
    for (const key of userOption.keys()) {
      optionMerge = optionMerge.set(key, userOption.get(key));
    }

    return optionMerge;
  }

  private get _requestOptions(): RequestOptions {
    const params: HttpParams = new HttpParams();
    const headers: HttpHeaders = this._requestHeaders;
    return {params, headers, withCredentials: false, observe: 'response'};
  }

  private get _requestHeaders(): HttpHeaders {
    const accessToken = this.accessToken

    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    });
  }

  private _responseSuccessHandler<T>(response: HttpResponse<T>): T {
    return this._responseJson<T>(response as HttpResponse<T>);
  }

  private _responseJson<T>(response: HttpResponse<T>): T | undefined {
    return response?.body ?? undefined;
  }

  private _responseErrorHandler(error: HttpErrorResponse): Observable<string> {
    this._broadcastError(error);
    return throwError(() => error);
  }

  private _refreshSession(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        this.accessToken = await this._authService.getAccessToken()
        resolve(true)
      } catch (error) {
        reject(error)
      }
    });
  }

  private _broadcastError(error: HttpErrorResponse): void {
    this.responseError$.next(error);
  }

  private _addBaseUrl(url: string): string {
    return environment.ApiUrl + url;
  }
}
