<div class="header">
	<h1>Claims</h1>
	<div *ngIf="rows.length">
		<a class="download" (click)="didClickRemoveFile()">Remove File</a>
	</div>
</div>
<table class="rows">
	<thead>
		<td>Member ID</td>
		<td>Payer</td>
		<td>Contracted Rate</td>
		<td>Copay</td>
		<td>Coinsurance</td>
		<td>Deductible</td>
		<td>Taxonomy Code</td>
		<td>POS</td>
		<td>Date</td>
		<td>Status</td>
	</thead>
	<tbody *ngIf="rows.length">
		<tr *ngFor="let row of rows">
			<td>{{ row.memberId }}</td>
			<td>{{ payerMap[row.payerId] }}</td>
			<td>{{ row.contractedRate | currency }}</td>
			<td>{{ row.copay | currency }}</td>
			<td>{{ row.coinsurance | currency }}</td>
			<td>{{ row.deductible | currency }}</td>
			<td>{{ row.taxonomyCode }}</td>
			<td>{{ row.placeOfService }}</td>
			<td>{{ row.date }}</td>
			<td *ngIf="!row.status"></td>
			<td *ngIf="row.status == 400 || row.status == 500" class="error"><span>Error: {{ row.error }}</span></td>
			<td *ngIf="row.status == 201" class="success"><span>Success</span></td>
		</tr>
	</tbody>
	<tbody *ngIf="!rows.length">
		<tr>
			<td colspan="10">
				<label for="file-upload">Select a file to upload<input id="file-upload" type="file" (change)="onFileSelected($event)" accept=".csv"></label>
			</td>
		</tr>
	</tbody>
</table>
<div class="footer" *ngIf="rows.length">
	<div>
		<span>{{ fileName }}</span>
		<a class="button" [class.loading]="loading" (click)="didClickUpload()">Submit</a>
	</div>
</div>
<div class="modal" *ngIf="showModal">
	<div>
		<h2>Review Columns</h2>
		<table>
			<tbody>
				<tr>
					<td>Member ID</td>
					<td>
						<select [(ngModel)]="map['memberId']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Payer ID</td>
					<td>
						<select [(ngModel)]="map['payerId']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Contracted Rate</td>
					<td>
						<select [(ngModel)]="map['contractedRate']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Copay</td>
					<td>
						<select [(ngModel)]="map['copay']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Coinsurance</td>
					<td>
						<select [(ngModel)]="map['coinsurance']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Deductible</td>
					<td>
						<select [(ngModel)]="map['deductible']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Taxonomy Code</td>
					<td>
						<select [(ngModel)]="map['taxonomyCode']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Place of Service</td>
					<td>
						<select [(ngModel)]="map['placeOfService']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
				<tr>
					<td>Date</td>
					<td>
						<select [(ngModel)]="map['date']">
							<option *ngFor="let field of fields">{{ field }}</option>
						</select>
					</td>
				</tr>
			</tbody>
		</table>
		<a class="button" (click)="mapFile()">Confirm</a>
	</div>
</div>
