import { Component, OnInit } from '@angular/core';
import { MixpanelService } from "./@shared/services/mixpanel.service"
import {Router, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'sohar-health';

  constructor(
    private mixpanelService: MixpanelService,
    private router: Router) {
    router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.mixpanelService.track("PageView", {
            url: val.url
          })
        }
    });
  }

  ngOnInit() {
    this.mixpanelService.init()
  }
}
