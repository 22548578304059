import { Component } from '@angular/core';
import { Report } from "../@shared/models/report"
import { ReportService } from "../@shared/services/report.service"

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent {

  reports: Report[] = []

  constructor(
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.getReports()
  }

  async getReports() {
    this.reports = await this.reportService.getReports()
  }

  async openReport(report: Report) {
    try {
      const reportUrl = await this.reportService.getReportUrl(report.reportId)

      if (!reportUrl) {
        alert("Report not found")
        return
      }

      window.open(reportUrl, "_blank")
    } catch (e) {
      alert("Report not found")
    }
  }
}
