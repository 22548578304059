export class Report {

	constructor(
		public created: Date,
		public reportId: string
	) {}

	static fromJson(data: any): Report {
		return new Report(
			new Date(data.created),
			data.reportId)
	}
}
