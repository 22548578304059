import { Fact } from "./fact"

export class Stat {

	constructor(
		public numberOfEligibilityRequests: number,
		public numberOfPatients: number,
		public numberOfERAs: number,
		public facts: Fact[]
	) {}

	static fromJson(data: any): Stat {
		return new Stat(
			data.numberOfEligibilityRequests || 0,
			data.numberOfPatients || 0,
			data.numberOfERAs || 0,
			data.facts.map(fact => Fact.fromJson(fact))
		)
	}
}
