import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Verification } from '../models/verification'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getVerifications(
    sandbox: boolean,
    searchValue: string|null,
    offset: number
  ): Promise<Verification[]> {
    return new Promise((resolve, reject) => {
      let url = `verifications?sandbox=${sandbox}&offset=${offset}`
      if (searchValue) {
        url += `&query=${searchValue}`
      }
      this.networkService.get(url).pipe(
        map((response: any) => {
          return response.verifications
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerification(verificationId: string): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`verifications/${verificationId}`).pipe(
        map((response: any) => {
          return Verification.fromJson(response)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createVerifications(verifications: any[], sandbox: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`verifications/batch`, {
        verifications,
        sandbox
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
