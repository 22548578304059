import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getClaims(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get("claims").pipe(
        map((response: any) => {
          return response.claims
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createClaims(claims: any[]): Promise<any> {
    claims = claims.map((claim: any) => {
      const coinsurance = isNaN(parseFloat(claim.coinsurance)) ? null : parseFloat(claim.coinsurance)
      const copay = isNaN(parseFloat(claim.copay)) ? null : parseFloat(claim.copay)
      const contractedRate = isNaN(parseFloat(claim.contractedRate)) ? null : parseFloat(claim.contractedRate)
      const deductible = isNaN(parseFloat(claim.deductible)) ? null : parseFloat(claim.deductible)
      return {
        coinsurance,
        contractedRate,
        copay,
        date: claim.date,
        deductible,
        memberId: claim.memberId,
        payerId: claim.payerId,
        taxonomyCode: claim.taxonomyCode,
        placeOfService: claim.placeOfService
      }
    })

    return new Promise((resolve, reject) => {
      this.networkService.post(`claims`, {
        claims
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
