import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Report } from '../models/report'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getReports(): Promise<Report[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`reports`).pipe(
        map((response: any) => {
          return response.reports.map(report => {
            return Report.fromJson(report)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getReportUrl(reportId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`reports/${reportId}`).pipe(
        map((response: any) => {
          return response.reportUrl
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
