export class Subscriber {

	constructor(
		public firstName: string,
		public lastName: string,
		public dateOfBirth: string) {}

	get fullName(): string {
		if (!this.firstName) {
			return null
		} else if (!this.lastName) {
			return this.firstName
		}

		return `${this.firstName} ${this.lastName}`
	}

	static fromJson(data: any): Subscriber {
		return new Subscriber(
			data.firstName,
			data.lastName,
			data.dateOfBirth)
	}
}