<div class="header">
	<h1>Settings</h1>
	<div *ngIf="isAdminUser">
		<a class="button" [routerLink]="['/invite-user']">Invite User</a>
	</div>
</div>
<div class="settings">
	<div>
		<div class="row settings-section">
			<div>
				<h4>Users</h4>
			</div>
			<div>
				<table>
					<thead>
						<td>Email Address</td>
						<td>Group</td>
					</thead>
					<tbody>
						<tr *ngFor="let user of users">
							<td [class.auth-user]="user.isAuthUser">{{ user.emailAddress }}</td>
							<td *ngIf="isAdminUser && !user.isAuthUser">
								<select [(ngModel)]="user.role" (change)="didChangeUserRole(user)">
									<option value="default">Default</option>
									<option value="admin">Admin</option>
								</select>
							</td>
							<td *ngIf="!isAdminUser || user.isAuthUser">{{ user.role == "admin" ? "Admin" : "Default" }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row settings-section" *ngIf="isAdminUser">
			<div>
				<h4>API Keys</h4>
			</div>
			<div>
				<table>
					<thead>
						<td>Environment</td>
						<td>API Key</td>
					</thead>
					<tbody>
						<tr>
							<td>Production</td>
							<td>{{ user?.productionApiKey }}<a class="copy" (click)="copyText(user?.productionApiKey)"><img src="assets/CopyDark.png" /></a></td>
						</tr>
						<tr>
							<td>Sandbox</td>
							<td>{{ user?.sandboxApiKey }}<a class="copy" (click)="copyText(user?.sandboxApiKey)"><img src="assets/CopyDark.png" /></a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row settings-section">
			<div></div>
			<div>
				<a [routerLink]="['/login']" class="button">Log Out</a>
			</div>
		</div>
	</div>
</div>
