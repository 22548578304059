import { Component } from '@angular/core'
import { CustomerService } from "../@shared/services/customer.service"

@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html'
})
export class WebhooksComponent {

  constructor(
    private customerService: CustomerService
  ) {}

  async ngOnInit() {
    window.location.href = await this.customerService.getWebhookPortalUrl()
  }
}
