import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '../@shared/services/customer.service'

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html'
})
export class InviteUserComponent {

  form: UntypedFormGroup

  constructor(
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    })
  }

  async onSubmit() {
    if (this.form.invalid) {
      alert("Please enter a valid email address")
      return
    }

    const emailAddress = this.form.value.emailAddress

    try {
      const user = await this.customerService.createUser(emailAddress)
      this.router.navigate(['settings'])
    } catch (error: any) {
      if (
        error &&
        error.status &&
        error.status == 400 &&
        error.error &&
        error.error.error
      ) {
        alert(error.error.error)
        return
      }
      console.log(error)
      alert("Something went wrong, please try again")
    }
  }
}
