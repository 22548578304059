import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { User } from '../models/user'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getWebhookPortalUrl(): Promise<string|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`webhook-portal`).pipe(
        map((response: any) => {
          return response.url
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createUser(emailAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post('user', {
        emailAddress
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateUser(
    cognitoId: string,
    role: string
   ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put('user', {
        cognitoId,
        role
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getUser(): Promise<User|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get('user').pipe(
        map((response: any) => {
          return User.fromJson(response.user)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get('users').pipe(
        map((response: any) => {
          return response.users.map(user => {
            return User.fromJson(user)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
