import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { VerificationComponent } from './verification/verification.component';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import { ReportsComponent } from './reports/reports.component';
import { CreateVerificationsComponent } from './create-verifications/create-verifications.component';
import { PayersComponent } from './payers/payers.component';
import { CreateClaimsComponent } from './create-claims/create-claims.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { WebhooksComponent } from './webhooks/webhooks.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { VerificationsComponent } from './verifications/verifications.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { TotpComponent } from './totp/totp.component';
import { NetworkStatusComponent } from './network-status/network-status.component';
import { NetworkStatusDetailComponent } from './network-status-detail/network-status-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    SettingsComponent,
    VerificationComponent,
    CreatePatientComponent,
    ReportsComponent,
    CreateVerificationsComponent,
    PayersComponent,
    CreateClaimsComponent,
    ResetPasswordComponent,
    ForgottenPasswordComponent,
    WebhooksComponent,
    InviteUserComponent,
    SetPasswordComponent,
    VerificationsComponent,
    CalculatorComponent,
    AuthenticatorComponent,
    TotpComponent,
    NetworkStatusComponent,
    NetworkStatusDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
