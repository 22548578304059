import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthUser } from '../@shared/models/auth-user';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';
@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html'
})
export class ForgottenPasswordComponent {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  async onSubmit() {
    if (this.form.invalid) {
      return
    }

    const email = this.form.value.email

    try {
      await this.authService.resetPassword(email)
      this.router.navigate([
        'reset-password',
        {
          email
        }
      ])
    } catch (error: any) {
      if (error) {
        if (error.code == "LimitExceededException") {
          alert("Attempt limit exceeded, please try after some time.")
        } else if (error.code == "NotAuthorizedException") {
          this.router.navigate(['set-password', { email }])
        } else {
          alert("Something went wrong, please try again")
        }
        return
      }
    }
  }
}
