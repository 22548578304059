<div class="login row">
  <div class="column">
    <img class="top-right" src="assets/top-right.png" />
    <img class="bottom-left" src="assets/bottom-left.png" />
    <div>
      <img class="logo" src="assets/Logo Peach-White.svg" />
      <h2>Eligibility Verification for Behavioral Health</h2>
      <h3>Verify patient eligibility for behavioral health reimbursements across hundreds of insurance providers</h3>
    </div>
  </div>
  <div class="column">
    <div>
      <h1>Sign in</h1>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <label>Email Address</label>
        <input
          type="email"
          formControlName="email"
          placeholder="Your email address"
          autocomplete="username"
          required
          autofocus
          />
        <label>Password</label>
        <input
          type="password"
          formControlName="password"
          placeholder="Your password"
          autocomplete="current-password"
          required
          />
        <button [disabled]="!form.valid">Sign In</button>
        <a [routerLink]="['/forgotten-password']">Reset Password</a>
      </form>
    </div>
  </div>
</div>
