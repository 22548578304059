<div class="reset-password">
  <h1>Reset password</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <label>Email Address</label>
    <input
      type="email"
      formControlName="email"
      required
      />
    <label>Confirmation Code</label>
    <input
      type="text"
      formControlName="confirmationCode"
      placeholder="Confirmation code sent to your email address"
      autocomplete="one-time-code"
      required
      autofocus
      />
    <label>Password</label>
    <input
      type="password"
      formControlName="password"
      placeholder="Choose a new password"
      autocomplete="new-password"
      required
      />
    <p class="password-requirements">Your password must be at least 10 characters long, and it must contain at least one lowercase character, one uppercase character, one number and one symbol.</p>
    <button [disabled]="!form.valid">Reset Password</button>
  </form>
</div>
