<div class="header">
	<h1>Create Verification</h1>
</div>
<section>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="row">
			<div>
				<label>First Name</label>
				<input type="text" formControlName="firstName" required autofocus />
			</div>
			<div>
				<label>Last Name</label>
				<input type="text" formControlName="lastName" required />
			</div>
		</div>
		<div class="row">
			<div>
				<label>Date of Birth</label>
				<input type="text" formControlName="dateOfBirth" placeholder="MM/DD/YYYY" required />
			</div>
			<div>
				<label>State</label>
				<select formControlName="state" required>
					<option selected value="">Select a State</option>
					<option *ngFor="let state of states" [value]="state" [innerHTML]="state"></option>
				</select>
			</div>
		</div>
		<div class="row">
			<div>
				<label>Member ID</label>
				<input type="text" formControlName="memberId" required />
			</div>
			<div>
				<label>Payer</label>
				<select formControlName="payerId" required>
					<option selected value="">Select a Payer</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId" [innerHTML]="payer.payerName"></option>
				</select>
			</div>
		</div>
		<div class="row">
			<div>
				<label>Environment</label>
				<select formControlName="sandbox" required>
					<option selected value="0">Production</option>
					<option value="1">Sandbox</option>
				</select>
			</div>
			<div class="dropdown">
				<label>NPI Number</label>
				<input type="text" formControlName="npi" required />
				<ul>
					<li *ngFor="let provider of filteredProviders" (click)="didSelectProvider(provider)">
						{{ provider.npi }} - {{ provider.organizationName }}
					</li>
					<li class="no-results" *ngIf="!filteredProviders.length">No results found</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div>
				<label>Taxonomy Code (optional)</label>
				<input type="text" formControlName="taxonomyCode" />
			</div>
			<div>
				<label>Place of Service</label>
				<select formControlName="placeOfServiceCode" required>
					<option selected value="">Select a Place of Service</option>
					<option *ngFor="let placeOfService of placesOfService" [value]="placeOfService.code" [innerHTML]="placeOfService.name"></option>
				</select>
			</div>
		</div>
		<button [disabled]="!form.valid">Create</button>
	</form>
</section>