import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthUser } from '../@shared/models/auth-user';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {

  form: UntypedFormGroup
  email: string

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      confirmationCode: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })

    this.email = this.route.snapshot.paramMap.get('email')

    if (!this.email) {
      this.router.navigate(['forgotten-password'])
      return
    } else {
      this.form.get('email').setValue(this.email)
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const email = this.email
    const confirmationCode = this.form.value.confirmationCode
    const password = this.form.value.password

    const errorMessage = this.authService.validatePassword(
      password,
      "password"
    )

    if (errorMessage) {
      alert(errorMessage)
      return
    }

    try {
      await this.authService.confirmVerificationCode(email, password, confirmationCode)
      this.router.navigate(['login', { email }])
    } catch (error) {
      alert("Something went wrong, please try again")
    }
  }
}
