<div class="header">
	<h1>Invite User</h1>
</div>
<section>
	<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
		<label>Email Address</label>
		<input
			type="email"
			formControlName="emailAddress"
			required
			spellcheck="false"
			/>
		<button>Send Invite</button>
	</form>
</section>