import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthUser } from '../@shared/models/auth-user';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.authService.logout()

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })

    const email = this.route.snapshot.paramMap.get('email')

    if (!email) {
      return
    }

    this.form.get('email').setValue(email)
  }

  async onSubmit() {
    if (this.form.invalid) {
      return
    }

    const email = this.form.value.email

    try {
      await this.authService.login(
        email,
        this.form.value.password
      )

      this.router.navigate([''])
    } catch (error) {
      if (error == COGNITO_RESPONSES.invalidEmailAddress) {
        alert("Your email address or password are incorrect")
      } else if (error == COGNITO_RESPONSES.invalidPassword) {
        alert("Your email address or password are incorrect")
      } else if (error == COGNITO_RESPONSES.newPasswordRequired) {
        this.router.navigate([
          'set-password',
          {
            email
          }
        ])
      } else if (error == COGNITO_RESPONSES.mfaSetup) {
        this.router.navigate([
          "authenticator"
        ],
        {
          queryParams: {
            email
          }
        })
      } else if (error == COGNITO_RESPONSES.mfaRequired) {
        this.router.navigate([
          "totp"
        ])
      } else {
        alert("Something went wrong")
      }
    }
  }
}
