<div class="reset-password">
  <h1>Set password</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <label>Email Address</label>
    <input
      type="email"
      formControlName="email"
      placeholder="Your email address"
      autocomplete="username"
      required
      spellcheck="false"
      />
    <label>Temporary Password</label>
    <input
      type="password"
      formControlName="password"
      placeholder="Enter your temporary password"
      autocomplete="current-password"
      required
      />
    <label>New Password</label>
    <input
      type="password"
      formControlName="newPassword"
      placeholder="Choose a new password"
      autocomplete="new-password"
      required
      />
    <p class="password-requirements">Your password must be at least 10 characters long, and it must contain at least one lowercase character, one uppercase character, one number and one symbol.</p>
    <button [disabled]="!form.valid">Set Password</button>
  </form>
</div>
