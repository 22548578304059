<div class="header">
	<h1>Dashboard</h1>
</div>
<div>
	<div class="row">
		<section class="stat">
			<span>Patients</span>
			<b>{{ numberOfPatients | number }}</b>
			<span class="info">
				<img src="assets/info-circle.svg" />
				<p>The total number of patients</p>
			</span>
		</section>
		<section class="stat">
			<span>Verifications</span>
			<b>{{ numberOfEligibilityRequests | number }}</b>
			<span class="info">
				<img src="assets/info-circle.svg" />
				<p>The total number of eligibility verifications</p>
			</span>
		</section>
		<section class="stat">
			<span>ERAs</span>
			<b>{{ numberOfERAs | number }}</b>
			<span class="info">
				<img src="assets/info-circle.svg" />
				<p>The total number of Electronic Remittance Advice records</p>
			</span>
		</section>
	</div>
	<div class="row">
		<section class="chart">
			<span>Patients</span>
			<canvas id="chartTwo">{{ chartTwo }}</canvas>
		</section>
		<section class="chart">
			<span>Verifications</span>
			<canvas id="chartOne">{{ chartOne }}</canvas>
		</section>
	</div>
</div>