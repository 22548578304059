<div class="reset-password">
  <h1>Reset password</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <label>Email Address</label>
    <input
      type="email"
      formControlName="email"
      placeholder="Your email address"
      autocomplete="username"
      required
      autofocus
      />
    <button [disabled]="!form.valid">Reset Password</button>
  </form>
</div>
