import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthUser } from '../@shared/models/auth-user';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent {

  form: UntypedFormGroup
  email: string

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    })

    this.email = this.route.snapshot.paramMap.get('email')

    if (this.email) {
      this.form.get('email').setValue(this.email)
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const email = this.form.value.email
    const password = this.form.value.password
    const newPassword = this.form.value.newPassword

    const temporaryPasswordErrorMessage = this.authService.validatePassword(
      password,
      "temporary password"
    )

    if (temporaryPasswordErrorMessage) {
      alert(temporaryPasswordErrorMessage)
      return
    }

    const errorMessage = this.authService.validatePassword(
      newPassword,
      "new password"
    )

    if (errorMessage) {
      alert(errorMessage)
      return
    }

    try {
      await this.authService.newPassword(email, password, newPassword)
      this.router.navigate([''])
    } catch (error) {
      if (error == COGNITO_RESPONSES.mfaSetup) {
        this.router.navigate([
          "authenticator"
        ],
        {
          queryParams: {
            email
          }
        })
      } else if (error == COGNITO_RESPONSES.mfaRequired) {
        this.router.navigate([
          "totp"
        ])
      } else {
        alert("Something went wrong")
      }
    }
  }
}
