import { Component } from '@angular/core';
import { Papa } from 'ngx-papaparse'
import { PayerService } from "../@shared/services/payer.service"
import { ClaimService } from "../@shared/services/claim.service"
import { Payer } from "../@shared/models/payer"
import * as levenshtein from "js-levenshtein"

@Component({
  selector: 'app-create-claims',
  templateUrl: './create-claims.component.html'
})
export class CreateClaimsComponent {

  data: any[] = []

  rows: any[] = []

  fileName: string|null = null

  fields: string[] = []

  showModal = false

  loading: boolean = false

  map: any = {
    "coinsurance": "",
    "contractedRate": "",
    "copay": "",
    "date": "",
    "deductible": "",
    "memberId": "",
    "payerId": "",
    "taxonomyCode": "",
    "placeOfService": ""
  }

  payerMap: { [payerId: string]: string } = {}

  constructor(
    private papa: Papa,
    private payerService: PayerService,
    private claimService: ClaimService) {}

  async ngOnInit() {

    const payers = await this.payerService.getPayers()

    for (const payer of payers) {
      this.payerMap[payer.payerId] = payer.payerName
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0]

    if (!file) {
      alert("Invalid file")
      return
    }

    this.fileName = file.name

    this.papa.parse(file, {
      header: true,
      transformHeader: (h) => {
        return h.trim()
      },
      complete: (results, file) => {
        if (!results || !results.data) {
          alert("Invalid file")
          return
        } else if (results.data.length > 500) {
          alert("The CSV must contain a maximum of 500 rows")
          return
        }
        this.fields = results.meta.fields
        this.createMap(this.fields)
        this.data = results.data
        this.fields.unshift("")
        this.showModal = true
      }
    })
  }

  createMap(fields: string[]) {
    for (const prop in this.map) {
      const sortedFields = fields.sort((a, b) => {
        const aDistance = levenshtein(prop, a)
        const bDistance = levenshtein(prop, b)
        return aDistance == bDistance ? 0 : (aDistance < bDistance ? -1 : 1)
      })
      this.map[prop] = sortedFields[0]
    }
  }

  mapFile() {
    this.rows = this.data.map((row: any, index: number) => {
      const res = {}
      for (const property in this.map) {
        res[property] = row[this.map[property]]
      }
      return res
    })
    this.showModal = false
  }

  async didClickUpload() {
    this.loading = true

    try {
      const res = await this.claimService.createClaims(this.rows)
      const successCount = res.results.filter((result) => result.status == 201).length
      const errorCount = res.results.filter((result) => result.status != 201).length
      alert(`Upload complete: ${successCount} of ${res.results.length} claims successfully created, ${errorCount} errors`)
      for (const [index, result] of res.results.entries()) {
        this.rows[index].status = result.status
        this.rows[index].error = result.error
      }
    } catch (error: any) {
      alert("Something went wrong, please try again")
    } finally {
      this.loading = false
    }
  }

  didClickRemoveFile() {
    this.data = []
    this.rows = []
    this.fileName = null
    this.fields = []
  }
}
