import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Stat } from '../models/stat'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class StatService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getStats(): Promise<Stat> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`stats`).pipe(
        map((response: any) => {
          return Stat.fromJson(response.stats)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
