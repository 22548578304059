import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './@shared/guards/auth.guard';
import { SettingsComponent } from './settings/settings.component'
import { VerificationComponent } from './verification/verification.component'
import { CreateVerificationsComponent } from './create-verifications/create-verifications.component'
import { CreatePatientComponent } from './create-patient/create-patient.component'
import { ReportsComponent } from "./reports/reports.component"
import { PayersComponent } from "./payers/payers.component"
import { CreateClaimsComponent } from "./create-claims/create-claims.component"
import { ResetPasswordComponent } from "./reset-password/reset-password.component"
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component"
import { WebhooksComponent } from "./webhooks/webhooks.component"
import { InviteUserComponent } from "./invite-user/invite-user.component"
import { SetPasswordComponent } from "./set-password/set-password.component"
import { VerificationsComponent } from "./verifications/verifications.component"
import { CalculatorComponent } from "./calculator/calculator.component"
import { AuthenticatorComponent } from "./authenticator/authenticator.component"
import { TotpComponent } from "./totp/totp.component"

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'calculator',
    component: CalculatorComponent
  },
  {
    path: 'authenticator',
    component: AuthenticatorComponent
  },
  {
    path: 'totp',
    component: TotpComponent
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'verifications',
        component: VerificationsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'payers',
        component: PayersComponent
      },
      {
        path: 'verifications/:id',
        component: VerificationComponent
      },
      {
        path: 'create-patient',
        component: CreatePatientComponent
      },
      {
        path: 'create-verifications',
        component: CreateVerificationsComponent
      },
      {
        path: 'create-claims',
        component: CreateClaimsComponent
      },
      {
        path: 'webhooks',
        component: WebhooksComponent
      },
      {
        path: 'invite-user',
        component: InviteUserComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
