import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * Initialize mixpanel.
   *
   * @memberof MixpanelService
   */
  init(): void {
    mixpanel.init(environment.MixpanelId);
  }

  /**
   * Identify user.
   *
   * @param {string} userId
   * @memberof MixpanelService
   */
  identify(userId: string): void {
    mixpanel.identify(userId);
  }

  /**
   * Create profile.
   *
   * @param {string} companyName
   * @param {string} emailAddress
   * @memberof MixpanelService
   */
  createProfile(companyName: string, emailAddress: string): void {
    mixpanel.people.set({
      companyName,
      $email: emailAddress
    })
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
