import { Component, OnInit } from '@angular/core';

import { CustomerService } from '../@shared/services/customer.service'
import { AuthService } from "../@shared/services/auth.service"
import { map } from 'rxjs/operators'
import { User } from '../@shared/models/user'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  user: User
  users: User[] = []
  isAdminUser: boolean = false

  constructor(
    private customerService: CustomerService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    const groups = await this.authService.getUserGroups()
    this.isAdminUser = groups.includes("admin")
    this.users = await this.customerService.getUsers()
    if (this.isAdminUser) {
      this.user = await this.customerService.getUser()
    }
  }

  copyText(value) {
    navigator.clipboard.writeText(value)
  }

  async didChangeUserRole(user: User) {
    await this.customerService.updateUser(
      user.cognitoId,
      user.role
    )
  }
}
