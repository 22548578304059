import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Payer } from '../models/payer'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class PayerService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getPayers(): Promise<Payer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`payers`).pipe(
        map((response: any) => {
          return response.payers
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
